import { Page, Text } from "./Comp";

function NotFound() {
  return (
    <Page label="404">
      <Text>Page not found</Text>
    </Page>
  );
}

export default NotFound;
