import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import parse from "html-react-parser";
import {
  CharacterType,
  LocationType,
  OrganizationType,
  StatsCharaDefenseType,
} from "./Types";
import {
  getOrganizations,
  getSpecies,
  isGameMaster,
  isListed,
} from "./Database";

// Component
interface PageProps {
  children?: React.ReactNode | String;
  label?: string;
  showGM?: boolean;
}
export function Page({ children, ...props }: PageProps) {
  return (
    <div
      style={{
        maxWidth: 1000,
        margin: !isMobile ? 20 : undefined,
        flex: 1,
        flexDirection: "column",
        display: "flex",
        backgroundColor: "#121212",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        minHeight: 600,
        borderRadius: !isMobile ? 10 : undefined,
      }}
    >
      {props.showGM ? (
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>
            <Header1>{props.label}</Header1>
          </div>
          <div style={{ flex: 1 }}>
            <Header1>
              <GameIcon />
            </Header1>
          </div>
        </div>
      ) : (
        <Header1>{props.label}</Header1>
      )}
      {children}
    </div>
  );
}

// Headers
interface HeaderProps {
  children?: React.ReactNode | String;
  style?: React.CSSProperties;
  key?: string | undefined;
}
export function Header1({ children, ...props }: HeaderProps) {
  return (
    <h1
      id={props.key}
      style={{
        marginBottom: 0,
        fontSize: 36,
        fontFamily: "DeathStar",
        fontWeight: 400,
        color: "#f0f0f0",
        ...props.style,
      }}
    >
      {children}
    </h1>
  );
}
export function Header2({ children, ...props }: HeaderProps) {
  return (
    <h2
      style={{
        fontSize: 24,
        color: "#f0f0f0",
        ...props.style,
      }}
    >
      {children}
    </h2>
  );
}
export function Header3({ children, ...props }: HeaderProps) {
  return (
    <h3
      style={{
        fontSize: 20,
        marginBottom: 0,
        color: "#f0f0f0",
        ...props.style,
      }}
    >
      {children}
    </h3>
  );
}
export function Header4({ children, ...props }: HeaderProps) {
  return (
    <h4
      style={{
        fontWeight: "bold",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        fontSize: 18,
        marginTop: 10,
        marginBottom: 0,
        color: "#f0f0f0",
        ...props.style,
      }}
    >
      {children}
    </h4>
  );
}

// Text
interface TextProps {
  children?: React.ReactNode | String;
  style?: React.CSSProperties;
}
export function Text({ children, ...props }: TextProps) {
  return (
    <p
      style={{
        marginTop: 4,
        fontSize: 18,
        color: "#f0f0f0",
        ...props.style,
      }}
    >
      {children}
    </p>
  );
}
export function AurekBeshSpan({ children, ...props }: TextProps) {
  return (
    <span
      style={{
        fontSize: 14,
        fontFamily: "AurekBesh",
        wordSpacing: 6,
        ...props.style,
      }}
    >
      {children}
    </span>
  );
}
export function Credits(props: { style?: React.CSSProperties }) {
  return (
    <span
      style={{
        fontSize: 16,
        fontFamily: "AurekBeshNarrow",
        color: "#f0f0f0",
        fontWeight: 200,
        ...props.style,
      }}
    >
      $
    </span>
  );
}

export function WookiPediaIcon() {
  return (
    <span
      style={{
        color: "#d7bf78",
        display: "inline-block",
        fontSize: 8,
        position: "relative",
        top: -8,
        paddingLeft: 1,
        fontFamily: "AurekBesh",
      }}
    >
      B
    </span>
  );
}
export function GameIcon(props: { style?: React.CSSProperties }) {
  return (
    <>
      {!isGameMaster() ? (
        <div
          style={{
            position: "revert",
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <a
            style={{
              color: "#d7bf78",
              fontSize: 8,
              letterSpacing: -1,
              fontFamily: "AurekBesh",
              ...props.style,
            }}
            href={"http://sw.gm.evul.nu" + window.location.pathname}
          >
            GM
          </a>
        </div>
      ) : (
        <div
          style={{
            position: "revert",
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <a
            style={{
              color: "#d7bf78",
              fontSize: 8,
              fontFamily: "AurekBesh",
              ...props.style,
            }}
            href={"http://sw.world.evul.nu" + window.location.pathname}
          >
            WD
          </a>
        </div>
      )}
    </>
  );
}

export function ImageBlock(props: {
  src: string | undefined;
  alt: string;
  url?: string | undefined;
}) {
  if (props.src === undefined && props.url === undefined) {
    return <></>;
  }
  return (
    <div
      style={{
        padding: 10,
        backgroundColor: "#242424",
        borderRadius: 10,
        width: 200,
        maxWidth: 200,
        maxHeight: props.url !== undefined ? 222 : 200,
        float: "right",
      }}
    >
      {props.src !== undefined ? (
        <img
          style={{ maxWidth: 200, maxHeight: 200 }}
          src={props.src}
          alt={props.alt}
        />
      ) : (
        <></>
      )}
      {props.url !== undefined ? (
        <Text style={{ fontSize: 14, margin: 0, textAlign: "center" }}>
          <a
            href={props.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#f0f0f0" }}
          >
            Show on Wookieepedia
            <WookiPediaIcon />
          </a>
        </Text>
      ) : (
        <></>
      )}
    </div>
  );
}

interface DataBlockProps {
  children?: React.ReactNode | String;
  data: LocationType | CharacterType | OrganizationType;
}
export function DataBlock({ children, ...props }: DataBlockProps) {
  const data = props.data;

  const organizationData = getOrganizations();
  const getSpeciesLink = (species: string) => {
    const speciesData = getSpecies();

    for (const [key, value] of Object.entries(speciesData)) {
      if (key.toLowerCase() === species.toLowerCase()) {
        return (
          <a href={value} style={{ color: "#ffffff" }}>
            {species}
            <WookiPediaIcon />
          </a>
        );
      }
    }
    return <>{species}</>;
  };

  const printFactions = (factions: string[]) => {
    return factions.map((faction) => {
      if (faction === "") {
        return <></>;
      }
      if (faction !== undefined && faction in organizationData) {
        const displayName = organizationData[faction]["displayName"];

        if (isListed(organizationData[faction])) {
          return (
            <tr key={faction}>
              <td>
                <Url style={{ fontSize: 16 }} href={"/organization/" + faction}>
                  {displayName}
                </Url>
              </td>
            </tr>
          );
        } else {
          return (
            <tr key={faction}>
              <td style={{ fontSize: 16 }}>{displayName}</td>
            </tr>
          );
        }
      }
      return (
        <tr key={faction}>
          <td style={{ fontSize: 16 }}>{faction}</td>
        </tr>
      );
    });
  };

  const printSystemAffiliation = (affiliation: string) => {
    const displayName = organizationData[affiliation]["displayName"];
    return (
      <tr>
        <th style={{ textAlign: "left" }}>Affiliation:</th>
        <td>
          <Link style={{ fontSize: 14 }} to={"/organization/" + affiliation}>
            {displayName}
          </Link>
        </td>
      </tr>
    );
  };

  const displayName = data["displayName"] ? data["displayName"] : undefined;
  const image = data["image"] ? data["image"] : undefined;
  const wikiUrl = data["url"] ? data["url"] : undefined;

  let astroRegion = undefined;
  let astroSector = undefined;
  let astroSystem = undefined;
  let astroGrid = undefined;
  let astroMapUrl = undefined;
  if ("astrographicalData" in data && data["astrographicalData"]) {
    astroRegion =
      data["astrographicalData"] && data["astrographicalData"]["region"]
        ? data["astrographicalData"]["region"]
        : undefined;
    astroSector =
      data["astrographicalData"] && data["astrographicalData"]["sector"]
        ? data["astrographicalData"]["sector"]
        : undefined;
    astroSystem =
      data["astrographicalData"] && data["astrographicalData"]["system"]
        ? data["astrographicalData"]["system"]
        : undefined;
    astroGrid =
      data["astrographicalData"] && data["astrographicalData"]["grid"]
        ? data["astrographicalData"]["grid"]
        : undefined;
    astroMapUrl =
      data["astrographicalData"] && data["astrographicalData"]["mapUrl"]
        ? data["astrographicalData"]["mapUrl"]
        : undefined;
  }
  const affiliation =
    "affiliation" in data && data["affiliation"]
      ? data["affiliation"]
      : undefined;

  const charName = "name" in data && data["name"] ? data["name"] : undefined;
  const charSpecies =
    "species" in data && data["species"] ? data["species"] : undefined;
  const charGender =
    "gender" in data && data["gender"] ? data["gender"] : undefined;
  const charFactions =
    "factions" in data && data["factions"] ? data["factions"] : undefined;
  const charProfession =
    "profession" in data && data["profession"] ? data["profession"] : undefined;

  return (
    <div
      style={{
        padding: 10,
        backgroundColor: "#242424",
        borderRadius: 10,
        width: 200,
        maxWidth: 200,
        float: "right",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 6,
        }}
      >
        <Text style={{ fontWeight: "bold" }}>{charName || displayName}</Text>
      </div>

      {image && (
        <img
          style={{ maxWidth: 200, maxHeight: 200 }}
          src={image}
          alt={image}
        />
      )}
      {wikiUrl && (
        <Text
          style={{
            fontSize: 14,
            margin: 0,
            textAlign: "center",
            paddingBottom: 14,
          }}
        >
          <WikiArticle href={wikiUrl}>Show on Wookieepedia</WikiArticle>
        </Text>
      )}
      {affiliation ? (
        <table style={{ color: "#f0f0f0", paddingBottom: 15 }}>
          <tbody>
            <tr>
              <th style={{ textAlign: "left", fontSize: 14, paddingBottom: 2 }}>
                Information:
              </th>
            </tr>
            {affiliation ? printSystemAffiliation(affiliation) : null}
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {astroRegion || astroSector || astroSystem || astroGrid ? (
        <table style={{ color: "#f0f0f0", fontSize: 16 }}>
          <tbody>
            <tr>
              <th
                colSpan={2}
                style={{ textAlign: "left", fontSize: 14, paddingBottom: 2 }}
              >
                Astrographical information:
              </th>
            </tr>
            {astroRegion && (
              <tr>
                <th style={{ textAlign: "left" }}>Region:</th>
                <td>{astroRegion}</td>
              </tr>
            )}
            {astroSector && (
              <tr>
                <th style={{ textAlign: "left" }}>Sector:</th>
                <td>{astroSector}</td>
              </tr>
            )}
            {astroSystem && (
              <tr>
                <th style={{ textAlign: "left" }}>System:</th>
                <td>{astroSystem}</td>
              </tr>
            )}
            {astroGrid && (
              <tr>
                <th style={{ textAlign: "left" }}>Grid:</th>
                <td>{astroGrid}</td>
              </tr>
            )}
            {astroMapUrl && (
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <WikiArticle style={{ fontSize: 14 }} href={astroMapUrl}>
                    Show on map
                  </WikiArticle>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {charSpecies || charGender ? (
        <div>
          <table style={{ color: "#f0f0f0", fontSize: 16 }}>
            <tbody>
              <tr>
                <th
                  colSpan={2}
                  style={{ textAlign: "left", fontSize: 14, paddingBottom: 2 }}
                >
                  Descriptive information:
                </th>
              </tr>
              {charSpecies && (
                <tr>
                  <th style={{ textAlign: "left" }}>Species:</th>
                  <td>{getSpeciesLink(charSpecies)}</td>
                </tr>
              )}
              {charGender && (
                <tr>
                  <th style={{ textAlign: "left" }}>Gender:</th>
                  <td>{charGender}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
      {charProfession ? (
        <table style={{ color: "#f0f0f0", fontSize: 16, paddingTop: 15 }}>
          <tbody>
            <tr>
              <th
                colSpan={2}
                style={{ textAlign: "left", fontSize: 14, paddingBottom: 2 }}
              >
                Characteristics:
              </th>
            </tr>
            {charProfession && (
              <tr>
                <th style={{ textAlign: "left" }}>Profession:</th>
                <td>{charProfession}</td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {charFactions ? (
        <table style={{ color: "#f0f0f0", paddingTop: 15 }}>
          <tbody>
            <tr>
              <th style={{ textAlign: "left", fontSize: 14, paddingBottom: 2 }}>
                Affiliations:
              </th>
            </tr>
            {charFactions ? printFactions(charFactions) : null}
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
}
interface StatsBlockProps {
  data: CharacterType;
}
export function StatsBlock({ ...props }: StatsBlockProps) {
  const data = props.data;

  if (!data["stats"]) return <></>;
  if (!data["stats"]["characteristics"]) return <></>;

  const characterName =
    "name" in data && data["name"] ? data["name"] : undefined;
  const displayName = data["displayName"] ? data["displayName"] : undefined;
  const name = displayName || characterName;

  const charTypeRaw = data["type"];
  const characterType = charTypeRaw
    ? capitalizeFirstLetter(charTypeRaw)
    : undefined;

  const characteristics = data["stats"]["characteristics"];
  const brawn = characteristics["brawn"] ? characteristics["brawn"] : 0;
  const agility = characteristics["agility"] ? characteristics["agility"] : 0;
  const intellect = characteristics["intellect"]
    ? characteristics["intellect"]
    : 0;
  const cunning = characteristics["cunning"] ? characteristics["cunning"] : 0;
  const willpower = characteristics["willpower"]
    ? characteristics["willpower"]
    : 0;
  const presence = characteristics["presence"]
    ? characteristics["presence"]
    : 0;

  const soak = characteristics["soak"] ? characteristics["soak"] : brawn;
  const wound = characteristics["wound"]
    ? characteristics["wound"]
    : brawn + 12;
  const strain = characteristics["strain"]
    ? characteristics["strain"]
    : willpower + 12;
  const defense = characteristics["defense"]
    ? characteristics["defense"]
    : { melee: 0, range: 0 };

  const book = data["stats"]["book"];
  const bookRef = !!book ? book["title"] : undefined;
  const bookTopic = !!book ? book["topic"] : undefined;
  const bookPage = !!book ? book["page"] : undefined;

  function CharBox(props: { label: string; points: number }) {
    return (
      <div
        style={{
          backgroundColor: "#45453c",
          padding: 3,
          margin: 5,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            width: 55,
            height: 65,
            maxHeight: 65,
            maxWidth: 55,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: 0,
              padding: 0,
              height: 50,
              width: 50,
              backgroundColor: "#f1f3fb",
              textAlign: "center",
              alignContent: "center",
              fontSize: 18,
              borderRadius: 100,
              fontWeight: 700,
            }}
          >
            {props.points}
          </div>
          <div
            style={{
              height: 20,
              width: 60,
              margin: 0,
              padding: 0,
              flex: 1,
              fontSize: 12,
              textAlign: "center",
              backgroundColor: "#570d10",
              color: "#f0f0f0",
              borderRadius: 2,
            }}
          >
            {props.label}
          </div>
        </div>
      </div>
    );
  }
  function CharBlock(props: {
    label: string;
    points: number | StatsCharaDefenseType;
  }) {
    return (
      <div
        style={{
          maxWidth: 90,
          maxHeight: 50,
          backgroundColor: "#322639",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: 5,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            height: 20,
            margin: 0,
            padding: 0,
            textAlign: "center",
            color: "#f0f0f0",
            fontSize: 12,
          }}
        >
          {props.label}
        </div>
        <div
          style={{
            margin: 0,
            padding: 0,
            height: 50,
            width: 90,
            backgroundColor: "#f1f3fb",
            textAlign: "center",
            alignContent: "center",
            fontSize: 18,
            fontWeight: 700,
            borderRadius: "0 0 5px 5px",
          }}
        >
          {typeof props.points === "number" && props.points}
          {typeof props.points === "object" && (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  borderStyle: "solid",
                  borderWidth: "0 1px 0 0",
                }}
              >
                <div style={{ fontSize: 17 }}>{props.points["melee"]}</div>
                <div style={{ fontSize: 9 }}>Melee</div>
              </div>
              <div
                style={{
                  flex: 1,
                  borderStyle: "solid",
                  borderWidth: "0 0 0 1px",
                }}
              >
                <div style={{ fontSize: 17 }}>{props.points["range"]}</div>
                <div style={{ fontSize: 9 }}>Range</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        marginTop: 20,
        paddingTop: 0,
        backgroundColor: "#242424",
        borderRadius: 10,
      }}
    >
      <div style={{ padding: 10 }}>
        <Header2 style={{ marginTop: 5, marginBottom: 5 }}>
          {name}
          {characterType && <> ({characterType})</>}
        </Header2>
        <div
          style={{
            flex: !isMobile ? 1 : undefined,
            display: !isMobile ? "flex" : undefined,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  backgroundColor: "#ab998e",
                  borderRadius: 10,
                }}
              >
                <CharBox label="Brawn" points={brawn} />
                <CharBox label="Agility" points={agility} />
                <CharBox label="Intellect" points={intellect} />
                <CharBox label="Cunning" points={cunning} />
                <CharBox label="Willpower" points={willpower} />
                <CharBox label="Presence" points={presence} />
              </div>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CharBlock label="Soak" points={soak} />
              <CharBlock label="Wound" points={wound} />
              {charTypeRaw === "nemesis" && (
                <CharBlock label="Strain" points={strain} />
              )}
              <CharBlock label="defense" points={defense} />
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {book && bookRef && bookPage ? (
                <Text style={{ color: "#787d80", fontSize: 12 }}>
                  ({bookRef} {bookTopic && <>({bookTopic})</>}{" "}
                  {bookPage && <>p.{bookPage}</>})
                </Text>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
      </div>
    </div>
  );
}

// Links
interface LinkProps {
  children?: React.ReactNode | String;
  style?: React.CSSProperties;
  to: string;
}
export function Link({ children, ...props }: LinkProps) {
  return (
    <RouterLink
      to={props.to}
      rel="noopener noreferrer"
      style={{
        color: "#f0f0f0",
        fontSize: 18,
        ...props.style,
      }}
    >
      {children}
    </RouterLink>
  );
}

interface UrlProps {
  children?: React.ReactNode | String;
  href: string | undefined;
  style?: React.CSSProperties;
}
export function Url({ children, ...props }: UrlProps) {
  return (
    <a
      href={props.href}
      style={{ color: "#ffffff", ...props.style }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

interface WikiArticleProps {
  children?: React.ReactNode | String;
  href: string | undefined;
  style?: React.CSSProperties;
}
export function WikiArticle({ children, ...props }: WikiArticleProps) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#ffffff", ...props.style }}
    >
      {children}
      <WookiPediaIcon />
    </a>
  );
}

// List
interface ListProps {
  children?: React.ReactNode | String;
  style?: React.CSSProperties;
}
export function List({ children, ...props }: ListProps) {
  return (
    <ul
      style={{
        ...props.style,
      }}
    >
      {children}
    </ul>
  );
}
interface ListItemProps {
  children?: React.ReactNode | String;
  style?: React.CSSProperties;
  key?: String | Number;
}
export function ListItem({ children, ...props }: ListItemProps) {
  return (
    <li
      style={{
        color: "#f0f0f0",
        fontSize: 18,
        paddingBottom: 10,
        ...props.style,
      }}
    >
      {children}
    </li>
  );
}

// Buttons
export function Button(props: { to: string; display: string }) {
  return (
    <div>
      <RouterLink to={props.to} style={{ textDecoration: "none" }}>
        <div
          style={{
            width: 140,
            height: 38,
            margin: 5,
            fontFamily: "DeathStar",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#000000",
            color: "#f0f0f0",
            fontWeight: 400,
            borderStyle: "solid",
            borderRadius: 8,
            borderColor: "#ac7123",
            borderWidth: 2,
          }}
        >
          {props.display}
        </div>
      </RouterLink>
    </div>
  );
}

// Functions
export const handleText = (text: string) => {
  // Wiki Link
  text = text.replaceAll(
    "<WikiArticle href=",
    "<a class='link' target='_blank' rel='noopener noreferrer' href="
  );
  text = text.replaceAll("</WikiArticle>", "<span class='icon' >B</span></a>");
  // Internal Link
  text = text.replaceAll("<Link href=", "<a class='link' href=");
  text = text.replaceAll("</Link>", "</a>");

  return parse(text);
};
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
