import { useParams } from "react-router-dom";
import { DataBlock, handleText, Page, Text } from "./Comp";
import DoesNotExist from "./DoesNotExist";
import { OrganizationType } from "./Types";
import { existInGame, getOrganizations, isListed } from "./Database";

const organizationData = getOrganizations();

function NotFound(props: { organization?: string }) {
  return (
    <DoesNotExist
      headerText={"The organization you looking for does not exit"}
      captionText={props.organization + " does not exist"}
    />
  );
}

function Organization() {
  let { orgKey } = useParams();

  const organizationId: string = !!orgKey ? orgKey : "";

  const organization: OrganizationType = organizationData[organizationId];
  if (!organization) {
    return <NotFound organization={orgKey} />;
  }

  if (!isListed(organizationData[organizationId])) {
    return <NotFound organization={orgKey} />;
  }

  const displayName = organization["displayName"];
  const description = !!organization["body"] ? organization["body"] : undefined;
  const image = !!organization["image"] ? organization["image"] : undefined;
  return (
    <Page
      label={displayName}
      showGM={existInGame(organizationData[organizationId])}
    >
      <div style={{ minHeight: image !== undefined ? 200 : undefined }}>
        <DataBlock data={organizationData[organizationId]} />
        {description !== undefined &&
        description.length !== 0 &&
        description[0] !== "" ? (
          description.map((body, i) => (
            <Text key={body}>{handleText(body.toString())}</Text>
          ))
        ) : (
          <Text>No information given</Text>
        )}
      </div>
    </Page>
  );
}

export default Organization;
