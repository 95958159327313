import {
  Header2,
  ListItem,
  Link,
  Page,
  Text,
  Url,
  List,
  AurekBeshSpan,
} from "./Comp";

function Home() {
  return (
    <Page label="Home" showGM={true}>
      <Text>
        <AurekBeshSpan>In the shadow of age of the empire</AurekBeshSpan>
      </Text>
      <Text>This is a Star Wars Roleplaying Chronicle Wiki.</Text>
      <Text>
        The chronicle take place in a unspecified time after the foundation of
        the <Link to="/organization/galactic_empire">Galactic Empire</Link> and
        founding of{" "}
        <Link to="/organization/rebel_alliance">Rebel Alliance</Link>.
      </Text>
      <Text>
        The roleplaying game uses the <b>Age of Rebellion</b>,{" "}
        <b>Edge of the Empire</b> and <b>Force and Destiny</b> rulebooks.
      </Text>
      <Header2>Resources</Header2>
      <List style={{ marginTop: 0 }}>
        <ListItem>
          <Url href="https://hbernberg.carto.com/builder/6650a85d-b115-4680-ab97-721bf8a41a90/embed">
            Galaxy map
          </Url>{" "}
          |{" "}
          <Url href="http://www.swgalaxymap.com/wp-content/uploads/maps/GFFA-high.jpg">
            Galaxy map (High resolution image)
          </Url>
        </ListItem>
        <ListItem>
          <Url href="https://legendsofthegalaxy.com/oggdude-character-generator/">
            OggDude Character Generator
          </Url>
        </ListItem>
      </List>
    </Page>
  );
}

export default Home;
