import { Button, Link } from "./Comp";
import logo from "../logo.png";
import GMlogo from "../logoGM.png";
import { isGameMaster } from "./Database";

function Header() {
  return (
    <div
      style={{
        zIndex: 1,
        position: "sticky",
        top: 0,
        backgroundColor: "#000000",
        display: "flex",
        justifyContent: "center",
        borderStyle: "solid",
        borderWidth: "0px 0px 5px 0px ",
        borderColor: "#c7bca7",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          maxWidth: 1200,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <Link to={"/"}>
            <img src={isGameMaster() ? GMlogo : logo} alt="" height={60} />
          </Link>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <Button to={"/"} display={"Home"} />
          <Button to={"/timeline"} display={"Timeline"} />
          <Button to={"/character"} display={"Characters"} />
          <Button to={"/organization"} display={"organizations"} />
          <Button to={"/location"} display={"Locations"} />
          {isGameMaster() && <Button to={"/world"} display={"World"} />}
        </div>
      </div>
    </div>
  );
}

export default Header;
