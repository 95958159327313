import { isMobile } from "react-device-detect";
import { Page, Link, ListItem, List } from "./Comp";
import { existInGame, getOrganizations, isListed } from "./Database";

const organizationData = getOrganizations();

function Organizations() {
  return (
    <Page label="Organizations" showGM={true}>
      <div
        style={{
          flex: !isMobile ? 1 : undefined,
          display: !isMobile ? "flex" : undefined,
        }}
      >
        <div style={{ flex: 1 }}>
          <List>
            {!!organizationData &&
              Object.keys(organizationData).map((org) => {
                const organization = organizationData[org];

                if (!isListed(organization)) {
                  return <></>;
                }

                const displayName = organization["displayName"]
                  ? organization["displayName"]
                  : "";
                const orgType = organization["type"]
                  ? organization["type"]
                  : undefined;
                if (!displayName) return <></>;
                return (
                  <ListItem key={org}>
                    <Link
                      to={"/organization/" + org}
                      style={{
                        color: existInGame(organization)
                          ? "#f0f0f0"
                          : "#A9A9A9",
                      }}
                    >
                      {displayName} {orgType && " (" + orgType + ")"}
                    </Link>
                  </ListItem>
                );
              })}
          </List>
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
    </Page>
  );
}

export default Organizations;
