import { Page, Header2, Text, Credits, AurekBeshSpan } from "./Comp";
import { isGameMaster } from "./Database";
import NotFound from "./NotFound";

function World() {
  if (!isGameMaster()) {
    return <NotFound />;
  }

  return (
    <Page label="World">
      <Text>
        <AurekBeshSpan>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam,
          minus ratione aliquam optio blanditiis veritatis placeat quis aut
          natus ipsum saepe rem reiciendis labore iusto recusandae quas incidunt
          voluptatem sit.
        </AurekBeshSpan>
      </Text>
      <Text>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi
        obcaecati velit perferendis. Debitis, ratione tempora! Nostrum atque
        tempore vitae natus at fuga qui itaque repellendus rem dolor, reiciendis
        tenetur suscipit. Lorem ipsum dolor sit amet consectetur, adipisicing
        elit. Et hic qui magnam eum eius! Repellat quisquam asperiores ea
        cupiditate consectetur aperiam architecto mollitia fugit nemo, nesciunt
        veritatis ullam aliquid quos.
      </Text>
      <Header2>
        Wanted{" "}
        <AurekBeshSpan style={{ fontSize: 12, color: "#0087e9" }}>
          (Wanted)
        </AurekBeshSpan>
      </Header2>
      <Text>
        Jane Doe 10 000
        <Credits />
      </Text>
      <Header2>Items</Header2>
    </Page>
  );
}

export default World;
