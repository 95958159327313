import { Page, Text } from "./Comp";
import jediArchive from "../images/Jedi_Archives.png";
import { isMobile } from "react-device-detect";

function DoesNotExist(props: {
  headerText: string;
  captionText?: string | undefined;
}) {
  return (
    <Page label={props.headerText}>
      {props.captionText !== undefined && <Text>{props.captionText}</Text>}
      <img
        src={jediArchive}
        alt="Jedi Archive"
        style={{
          maxWidth: isMobile ? 420 : 600,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      />
      <Text>
        <i>"If an item does not appear in our records, it does not exist."</i>
      </Text>
    </Page>
  );
}

export default DoesNotExist;
