import { useParams } from "react-router-dom";
import DoesNotExist from "./DoesNotExist";
import {
  Header2,
  Header3,
  Header4,
  handleText,
  ImageBlock,
  ListItem,
  Link,
  Page,
  Text,
  WookiPediaIcon,
  List,
  DataBlock,
} from "./Comp";
import { LocationDataType, LocationSubDataType, LocationType } from "./Types";
import { existInGame, getCharacters, getLocations, isListed } from "./Database";

const locationData = getLocations();

function NotFound(props: { location?: string }) {
  return (
    <DoesNotExist
      headerText={"The location you looking for does not exit."}
      captionText={props.location + " seams not to exist."}
    />
  );
}

function LocationCharacters(props: {
  label: string;
  data: string[];
  category: number;
  existInGame: boolean;
}) {
  const charData = getCharacters();
  let dataArray = props.data;

  // Remove unlisted items
  for (const key in charData) {
    if (!isListed(charData[key])) {
      delete charData[key];
    }
  }
  // Clean up
  for (const key of props.data) {
    if (!(key in charData)) {
      dataArray = dataArray.filter((e) => e !== key);
    }
  }

  if (dataArray.length <= 0) return <></>;

  return (
    <>
      {props.category === 1 ? (
        <Header2 style={{ color: props.existInGame ? "#f0f0f0" : "#A9A9A9" }}>
          {props.label}
        </Header2>
      ) : (
        <></>
      )}
      {props.category === 2 ? (
        <Header3 style={{ color: props.existInGame ? "#f0f0f0" : "#A9A9A9" }}>
          {props.label}
        </Header3>
      ) : (
        <></>
      )}
      {props.category === 3 ? (
        <Header4 style={{ color: props.existInGame ? "#f0f0f0" : "#A9A9A9" }}>
          {props.label}
        </Header4>
      ) : (
        <></>
      )}
      <List>
        {dataArray.map((character, index) => {
          const char = charData[character];
          if (!(character in charData)) {
            return <ListItem key={index}>{character}</ListItem>;
          }
          const displayName = char["displayName"] ? char["displayName"] : "";
          if (!displayName) {
            return <></>;
          }
          const name = char["name"] ? char["name"] : "";
          const characterName = displayName || name || character;

          return (
            <ListItem key={index}>
              <Link
                to={"/character/" + character}
                style={{
                  color:
                    props.existInGame && existInGame(char)
                      ? "#f0f0f0"
                      : "#A9A9A9",
                }}
              >
                {characterName}
              </Link>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

function SubLocations(props: { label: string; data: LocationDataType }) {
  if (Object.keys(props.data).length < 0) {
    return <></>;
  }
  return (
    <>
      <Header2>{props.label}</Header2>
      {Object.keys(props.data).map((location) => {
        const loc = props.data[location];
        if (!isListed(loc)) {
          return <></>;
        }
        const displayName = loc["displayName"] ? loc["displayName"] : "";
        const type = loc["type"] ? loc["type"] : "";
        const description = loc["body"] ? loc["body"] : undefined;
        const image = !!loc["image"] ? loc["image"] : undefined;
        const wikiUrl = loc["url"] ? loc["url"] : "";
        const charactersOfNote = loc["characters"] ? loc["characters"] : "";
        const sites: LocationDataType =
          loc["locations"] && loc["locations"]["site"] !== undefined
            ? loc["locations"]["site"]
            : {};
        return (
          <div key={location} style={{ marginLeft: 20 }}>
            <Header3>
              {displayName}
              {type ? " (" + type + ")" : ""}
              {wikiUrl && (
                <a href={wikiUrl} target="_blank" rel="noopener noreferrer">
                  <WookiPediaIcon />
                </a>
              )}
            </Header3>
            <div style={{ minHeight: image !== undefined ? 200 : undefined }}>
              <ImageBlock src={image} alt={displayName} />
              {description !== undefined &&
              description.length !== 0 &&
              description[0] !== "0" ? (
                description.map((body) => (
                  <Text key={body}>{handleText(body.toString())}</Text>
                ))
              ) : (
                <Text>No information exist for this location yet.</Text>
              )}
              {!!charactersOfNote &&
              Object.keys(charactersOfNote).length > 0 ? (
                <LocationCharacters
                  label={"Characters"}
                  data={charactersOfNote}
                  category={2}
                  existInGame={existInGame(loc)}
                />
              ) : (
                <></>
              )}
              {!!sites && Object.keys(sites).length > 0 ? (
                <SubLocationsSites data={sites} />
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}

function SubLocationsSites(props: { data: LocationDataType }) {
  if (!props.data) {
    return <></>;
  }
  if (Object.keys(props.data).length < 0) {
    return <></>;
  }
  return (
    <>
      {Object.keys(props.data).map((location, i) => {
        const loc = props.data[location];
        if (!isListed(loc)) {
          return <></>;
        }
        const displayName = loc["displayName"] ? loc["displayName"] : "";
        const type = loc["type"] ? loc["type"] : "";
        const description = loc["body"] ? loc["body"] : undefined;
        const image = !!loc["image"] ? loc["image"] : undefined;
        const wikiUrl = loc["url"] ? loc["url"] : "";
        const charactersOfNote = loc["characters"]
          ? loc["characters"]
          : undefined;
        return (
          <div key={i} style={{ marginLeft: 20 }}>
            <Header4
              style={{ color: existInGame(loc) ? "#f0f0f0" : "#A9A9A9" }}
            >
              {displayName}
              {type ? " (" + type + ")" : ""}
              {wikiUrl && (
                <a href={wikiUrl} target="_blank" rel="noopener noreferrer">
                  <WookiPediaIcon />
                </a>
              )}
            </Header4>
            <div style={{ minHeight: image !== undefined ? 200 : undefined }}>
              <ImageBlock src={image} alt={displayName} />
              {description !== undefined &&
              description.length !== 0 &&
              description[0] !== "0" ? (
                description.map((body) => (
                  <Text
                    key={"loc_body" + body}
                    style={{ color: existInGame(loc) ? "#f0f0f0" : "#A9A9A9" }}
                  >
                    {handleText(body.toString())}
                  </Text>
                ))
              ) : (
                <Text
                  style={{ color: existInGame(loc) ? "#f0f0f0" : "#A9A9A9" }}
                >
                  No information exist for this site yet.
                </Text>
              )}
              {charactersOfNote !== undefined &&
              Object.keys(charactersOfNote).length > 0 ? (
                <LocationCharacters
                  label={"Characters"}
                  data={charactersOfNote}
                  category={3}
                  existInGame={existInGame(loc)}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}

function Location() {
  // eslint-disable-next-line
  let { locKey, subKey, siteKey } = useParams();

  const locationId: string = !!locKey ? locKey : "";

  const location: LocationType = locationData[locationId];
  if (!location) {
    return <NotFound location={locKey} />;
  }

  if (!isListed(locationData[locationId])) {
    return <NotFound location={locKey} />;
  }

  const displayName = location["displayName"];
  const description = !!location["body"] ? location["body"] : [""];
  const image = !!location["image"] ? location["image"] : undefined;

  const charactersOfNote = !!location["characters"]
    ? location["characters"]
    : [];

  const locations: LocationSubDataType = !!location["locations"]
    ? location["locations"]
    : {};
  const groundLocations: LocationDataType = !!locations["ground"]
    ? locations["ground"]
    : {};
  const orbitalLocations: LocationDataType = !!locations["orbit"]
    ? locations["orbit"]
    : {};

  return (
    <Page label={displayName} showGM={existInGame(locationData[locationId])}>
      <div>
        <div style={{ minHeight: image !== undefined ? 200 : undefined }}>
          {/* <ImageBlock src={image} alt={displayName} url={wikiUrl} /> */}
          <DataBlock data={locationData[locationId]} />
          {description !== undefined &&
          description.length !== 0 &&
          description[0] !== "" ? (
            description.map((body, i) => (
              <Text key={"body" + i}>{handleText(body.toString())}</Text>
            ))
          ) : (
            <Text>No information given about this location exist yet.</Text>
          )}
          {!!charactersOfNote && Object.keys(charactersOfNote).length > 0 ? (
            <LocationCharacters
              label={"Characters of note"}
              data={charactersOfNote}
              category={1}
              existInGame={existInGame(location)}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {!!groundLocations && Object.keys(groundLocations).length > 0 ? (
            <SubLocations label={"Ground locations"} data={groundLocations} />
          ) : (
            <></>
          )}
          {!!orbitalLocations && Object.keys(orbitalLocations).length > 0 ? (
            <SubLocations label={"Orbital locations"} data={orbitalLocations} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </Page>
  );
}

export default Location;
