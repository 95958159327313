import { Page, Text } from "./Comp";

function Timeline() {
  return (
    <Page label="Timeline" showGM={true}>
      <Text>
        <i>
          The timeline is measured in <b>days</b> since game start.
        </i>
      </Text>
      <Text>0 - The adventure begins</Text>
    </Page>
  );
}

export default Timeline;
