import { isMobile } from "react-device-detect";
import {
  Page,
  Link,
  ListItem,
  List,
  capitalizeFirstLetter,
  Header2,
} from "./Comp";
import {
  existInGame,
  getCharacters,
  getOrganizations,
  isGameMaster,
  isListed,
} from "./Database";

const characterData = getCharacters();
const organizationData = getOrganizations();

function Characters() {
  function DefaultCharacters() {
    return (
      <>
        <List>
          {!!characterData &&
            Object.keys(characterData).map((key) => {
              const character = characterData[key];

              if (!isListed(character)) {
                return <></>;
              }

              const displayName = character["displayName"]
                ? character["displayName"]
                : "";
              const name = character["name"] ? character["name"] : "";
              const type = character["type"] ? character["type"] : undefined;

              if (!displayName && !name) {
                return <></>;
              }
              const characterName = displayName || name;

              const factionKey =
                !!character["factions"] && !!character["factions"][0]
                  ? character["factions"][0]
                  : undefined;
              const faction =
                factionKey !== undefined && factionKey in organizationData
                  ? organizationData[factionKey]["displayName"]
                  : character["factions"] && character["factions"][0];

              return (
                <ListItem key={key}>
                  <Link
                    to={"/character/" + key}
                    style={{ color: "#f0f0f0", fontSize: 18 }}
                  >
                    {characterName} {faction && " (" + faction + ")"}{" "}
                    {isGameMaster() &&
                      type &&
                      "[" + capitalizeFirstLetter(type) + "]"}
                  </Link>
                </ListItem>
              );
            })}
        </List>
      </>
    );
  }

  function GameMasterCharacters() {
    return (
      <div
        style={{
          flex: !isMobile ? 1 : undefined,
          display: !isMobile ? "flex" : undefined,
        }}
      >
        <div style={{ flex: 1 }}>
          <Header2>Characters</Header2>
          <List>
            {!!characterData &&
              Object.keys(characterData).map((key) => {
                const character = characterData[key];

                if (!isListed(character)) {
                  return <></>;
                }

                const displayName = character["displayName"]
                  ? character["displayName"]
                  : "";
                const name = character["name"] ? character["name"] : "";
                const type = character["type"] ? character["type"] : undefined;

                if (!displayName && !name) {
                  return <></>;
                }
                const characterName = displayName || name;

                const factionKey =
                  !!character["factions"] && !!character["factions"][0]
                    ? character["factions"][0]
                    : undefined;
                const faction =
                  factionKey !== undefined && factionKey in organizationData
                    ? organizationData[factionKey]["displayName"]
                    : character["factions"] && character["factions"][0];
                if (isGameMaster() && type === "minions") {
                  return <></>;
                }
                return (
                  <ListItem key={key}>
                    <Link
                      to={"/character/" + key}
                      style={{
                        color: existInGame(character) ? "#f0f0f0" : "#A9A9A9",
                        fontSize: 18,
                      }}
                    >
                      {characterName} {faction && " (" + faction + ")"}{" "}
                      {isGameMaster() &&
                        type &&
                        "[" + capitalizeFirstLetter(type) + "]"}
                    </Link>
                  </ListItem>
                );
              })}
          </List>
        </div>
        <div style={{ flex: 1 }}>
          <Header2>Nameless</Header2>
          <List>
            {!!characterData &&
              Object.keys(characterData).map((key) => {
                const character = characterData[key];

                if (!isListed(character)) {
                  return <></>;
                }

                const displayName = character["displayName"]
                  ? character["displayName"]
                  : "";
                const name = character["name"] ? character["name"] : "";
                const type = character["type"] ? character["type"] : undefined;

                if (!displayName && !name) {
                  return <></>;
                }
                const characterName = displayName || name;

                const factionKey =
                  !!character["factions"] && !!character["factions"][0]
                    ? character["factions"][0]
                    : undefined;
                const faction =
                  factionKey !== undefined && factionKey in organizationData
                    ? organizationData[factionKey]["displayName"]
                    : character["factions"] && character["factions"][0];
                if (isGameMaster() && type !== "minions") {
                  return <></>;
                }
                return (
                  <ListItem key={key}>
                    <Link
                      to={"/character/" + key}
                      style={{ color: "#f0f0f0", fontSize: 18 }}
                    >
                      {characterName} {faction && " (" + faction + ")"}{" "}
                      {isGameMaster() &&
                        type &&
                        "[" + capitalizeFirstLetter(type) + "]"}
                    </Link>
                  </ListItem>
                );
              })}
          </List>
        </div>
      </div>
    );
  }

  return (
    <Page label="Characters" showGM={true}>
      {isGameMaster() ? <GameMasterCharacters /> : <DefaultCharacters />}
    </Page>
  );
}

export default Characters;
