import jsonCharacter from "../data/characters.json";
import jsonCharacterMinions from "../data/characters_minions.json";
import jsonOrganization from "../data/organizations.json";
import jsonLocations from "../data/locations.json";
import jsonLocations_gyndine from "../data/locations_gyndine.json";
import jsonSpecies from "../data/species.json";
import jsonSettings from "../data/settings.json";
import {
  CharacterDataType,
  CharacterType,
  LocationDataType,
  LocationType,
  OrganizationDataType,
  OrganizationType,
  settingsType,
  SpeciesDataType,
} from "./Types";

// CharacterData
export function getCharacters(): CharacterDataType {
  const characterJsonData: any = { ...jsonCharacter, ...jsonCharacterMinions };
  const characterData: CharacterDataType = characterJsonData;
  return characterData;
}
export function getCharacter(id: string): CharacterType {
  const character = getCharacters()[id];
  return character;
}

// OrganizationData
export function getOrganizations(): OrganizationDataType {
  const organizationJsonData: any = jsonOrganization;
  const organizationData: OrganizationDataType = organizationJsonData;
  return organizationData;
}
export function getOrganization(id: string): OrganizationType {
  const organization = getOrganizations()[id];
  return organization;
}

// LocationData
export function getLocations(): LocationDataType {
  const locationJsonData: any = { ...jsonLocations, ...jsonLocations_gyndine };
  const locationData: LocationDataType = locationJsonData;
  return locationData;
}
export function getLocation(id: string): LocationType {
  const location = getLocations()[id];
  return location;
}

export function getSpecies(): SpeciesDataType {
  const speciesJsonData: any = jsonSpecies;
  const speciesData: SpeciesDataType = speciesJsonData;
  return speciesData;
}

export function getSettings(): settingsType {
  const settingsJsonData: any = jsonSettings;
  const settingsData: settingsType = settingsJsonData;
  return settingsData;
}

// Helper Functions
export function isGameMaster(): boolean {
  const settings = getSettings();
  const gameMaster =
    settings["gameMaster"] !== undefined ? settings["gameMaster"] : false;
  return gameMaster;
}
export function isListed(
  data: LocationType | CharacterType | OrganizationType
): boolean {
  if (isGameMaster()) return true;
  const listed = data["listed"] !== undefined ? data["listed"] : true;
  return listed;
}
export function existInGame(
  data: LocationType | CharacterType | OrganizationType
): boolean {
  const listed = data["listed"] !== undefined ? data["listed"] : true;
  return listed;
}
