import { useParams } from "react-router-dom";
import { DataBlock, handleText, Page, StatsBlock, Text } from "./Comp";
import DoesNotExist from "./DoesNotExist";
import { CharacterType } from "./Types";
import { existInGame, getCharacters, isGameMaster, isListed } from "./Database";

const characterData = getCharacters();

function NotFound(props: { character?: string }) {
  return (
    <DoesNotExist
      headerText={"The individual you looking for does not exit"}
      captionText={props.character + " does not exist"}
    />
  );
}

function Character() {
  // eslint-disable-next-line
  let { charKey } = useParams();

  const characterId: string = !!charKey ? charKey : "";

  const character: CharacterType = characterData[characterId];
  if (!character) {
    return <NotFound character={charKey} />;
  }

  if (!isListed(characterData[characterId])) {
    return <NotFound character={charKey} />;
  }

  const displayName = character["displayName"];
  const description = !!character["body"] ? character["body"] : undefined;
  const image = !!character["image"] ? character["image"] : undefined;

  return (
    <Page label={displayName} showGM={existInGame(character)}>
      <div style={{ minHeight: image !== undefined ? 200 : undefined }}>
        <DataBlock data={character} />

        {description !== undefined &&
        description.length !== 0 &&
        description[0] !== "0" ? (
          description.map((body, i) => (
            <Text key={"body" + i}>{handleText(body.toString())}</Text>
          ))
        ) : (
          <Text>No information given</Text>
        )}
      </div>
      {isGameMaster() && <StatsBlock data={character} />}
    </Page>
  );
}

export default Character;
