import React from "react";
import Characters from "./components/Characters";
import Header from "./components/Header";
import Home from "./components/Home";
import Locations from "./components/Locations";
import NotFound from "./components/NotFound";
import { Route, Routes } from "react-router-dom";
import Location from "./components/Location";
import Organizations from "./components/Organizations";
import Timeline from "./components/Timeline";
import Organization from "./components/Organization";
import Character from "./components/Character";
import World from "./components/World";

function App() {
  return (
    <div
      style={{
        backgroundColor: "#000000",
        width: "100%",
        height: "100vh",
      }}
    >
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Routes>
          <Route index element={<Home />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/organization" element={<Organizations />} />
          <Route path="/organization/:orgKey" element={<Organization />} />
          <Route path="/character" element={<Characters />} />
          <Route path="/character/:charKey" element={<Character />} />
          <Route path="/location" element={<Locations />} />
          <Route path="/location/:locKey" element={<Location />} />
          <Route path="/location/:locKey/:subKey" element={<Location />} />
          <Route
            path="/location/:locKey/:subKey/:siteKey"
            element={<Location />}
          />
          <Route path="/world" element={<World />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
