import { Page, Link, ListItem, List } from "./Comp";
import { existInGame, getLocations, isListed } from "./Database";
import { LocationDataType } from "./Types";

const locationData = getLocations();

function SubLocations(props: {
  parentLocation: string;
  data: LocationDataType;
}) {
  if (Object.keys(props.data).length < 0) {
    return <></>;
  }
  return (
    <>
      {Object.keys(props.data).map((location) => {
        const loc = props.data[location];
        const locDisplay = loc["displayName"] ? loc["displayName"] : "";
        const locType = loc["type"] ? loc["type"] : "";
        const parentLocationData = locationData[props.parentLocation];

        if (!isListed(loc)) {
          return <></>;
        }

        return (
          <ListItem
            style={{
              color: "#f0f0f0",
              fontSize: 18,
              paddingBottom: 10,
            }}
            key={props.parentLocation + "_" + location}
          >
            <Link
              to={"/location/" + props.parentLocation + "/" + location}
              style={{
                color:
                  existInGame(parentLocationData) && existInGame(loc)
                    ? "#f0f0f0"
                    : "#A9A9A9",
              }}
            >
              {locDisplay}
              {locType ? " (" + locType + ")" : ""}
            </Link>
          </ListItem>
        );
      })}
    </>
  );
}

function Locations() {
  if (!locationData) {
    console.error("Failed to load locations...");
    return <></>;
  }
  return (
    <Page label="Locations" showGM={true}>
      <List>
        {!!locationData &&
          Object.keys(locationData).map((location) => {
            const locations = !!locationData[location]["locations"]
              ? locationData[location]["locations"]
              : {};
            const groundLocations: LocationDataType =
              locations && locations["ground"] ? locations["ground"] : {};
            const orbitLocations: LocationDataType =
              locations && locations["orbit"] ? locations["orbit"] : {};

            const displayName = locationData[location]["displayName"];

            if (!isListed(locationData[location])) {
              return <></>;
            }

            return (
              <>
                <ListItem
                  style={{
                    fontSize: 18,
                    paddingBottom: 10,
                  }}
                  key={location}
                >
                  <Link
                    to={"/location/" + location}
                    style={{
                      color: existInGame(locationData[location])
                        ? "#f0f0f0"
                        : "#A9A9A9",
                    }}
                  >
                    {displayName}
                  </Link>
                </ListItem>
                <List>
                  {!!locationData && Object.keys(groundLocations).length > 0 ? (
                    <SubLocations
                      parentLocation={location}
                      data={groundLocations}
                    />
                  ) : (
                    <></>
                  )}
                  {!!locationData && Object.keys(groundLocations).length > 0 ? (
                    <SubLocations
                      parentLocation={location}
                      data={orbitLocations}
                    />
                  ) : (
                    <></>
                  )}
                </List>
              </>
            );
          })}
      </List>
    </Page>
  );
}

export default Locations;
